import { isPlatformServer } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ToastrService } from 'ngx-toastr';
import { skip } from 'rxjs/operators';
import { WINDOW } from '@amica-sso/data';
import { NetworkService } from './services/network.service';

@Component({
  selector: 'ams-root',
  template: ` <router-outlet></router-outlet> `,
})
@UntilDestroy()
export class AppComponent implements OnInit {
  constructor(
    private readonly _ngbModal: NgbModal,
    private readonly _router: Router,
    @Inject(PLATFORM_ID)
    private readonly _platformId: object,
    @Inject(WINDOW)
    private readonly _window: WindowProxy,
    private readonly _networkService: NetworkService,
    private readonly _toastrService: ToastrService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this._setRouterSubscription();
    this._setOnlineOfflineChangesSubscription();
  }

  private _setOnlineOfflineChangesSubscription(): void {
    if (isPlatformServer(this._platformId)) {
      return;
    }
    this._networkService.onlineChanges$.pipe(untilDestroyed(this), skip(1)).subscribe((online) => {
      if (online) {
        this._toastrService.info('Odzyskano połączenie internetowe.');
      } else {
        this._toastrService.error('Utracono połączenie internetowe.');
      }
    });
  }

  private _setRouterSubscription(): void {
    if (isPlatformServer(this._platformId)) {
      return;
    }
    this._router.events.pipe(untilDestroyed(this)).subscribe((routeEvent) => {
      if (routeEvent instanceof NavigationEnd) {
        this._ngbModal.dismissAll();
      }
    });
  }
}
