import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Router } from '@angular/router';
import { fromEvent, map, merge, Observable, of } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { WINDOW } from '@amica-sso/data';

@Injectable({ providedIn: 'root' })
export class NetworkService {
  public static instance: NetworkService | null = null;

  public onlineChanges$: Observable<boolean>;
  public connection$?: Observable<'slow-2g' | '2g' | '3g' | '4g' | '5g'>;

  constructor(
    @Optional()
    protected router: Router,
    @Inject(PLATFORM_ID)
    protected platformId: string,
    @Inject(WINDOW)
    private readonly _window: WindowProxy,
  ) {
    /* Store instance in a static property to allow access to injected services in the RxJS static operator
     * Should be done only once */
    if (!NetworkService.instance) {
      NetworkService.instance = this;
    }

    this.onlineChanges$ = isPlatformServer(this.platformId)
      ? of(true)
      : merge(
          fromEvent(this._window, 'online').pipe(map(() => true)),
          fromEvent(this._window, 'offline').pipe(map(() => false)),
        ).pipe(startWith(this.online));

    if (isPlatformServer(this.platformId)) {
      return;
    }
    // @ts-ignore
    const connection = this._window.navigator['connection'];
    if (!connection) {
      return;
    }

    this.connection$ = new Observable((observer) => {
      const onConnectionChange = () => {
        // @ts-ignore
        const effectiveType = this._window.navigator['connection']['effectiveType'];
        observer.next(effectiveType);
      };
      onConnectionChange();
      // @ts-ignore
      this._window.navigator['connection'].addEventListener('change', onConnectionChange);
      return () => {
        // @ts-ignore
        this._window.navigator['connection'].removeEventListener('change', onConnectionChange);
        observer.complete();
      };
    });
  }

  get online(): boolean {
    return isPlatformBrowser(this.platformId) ? navigator.onLine : true;
  }
}
