import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/angular';

Sentry.init({
  dsn: 'https://2b41b3485e4b4746a381c33a8518153c@sentry.h2h.tech/20',
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: ['localhost', 'https://test-sso.amica.pl', 'https://sso.amica.pl'],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  debug: false,
  autoSessionTracking: true,
  normalizeDepth: 7,
});

if (environment.production) {
  enableProdMode();
}
// <script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="76721911-5f47-4c69-8bff-0aaa1c3283c6" type="text/javascript" async></script>
// if (!environment.development) {
// const scriptEl = window.document.createElement('script');
// scriptEl.src = 'https://consent.cookiebot.com/uc.js';
// scriptEl.dataset['blockingmode'] = 'auto';
// scriptEl.dataset['cbid'] = '76721911-5f47-4c69-8bff-0aaa1c3283c6';
// scriptEl.id = 'Cookiebot';
// scriptEl.type = 'text/javascript';
// window.document.head.appendChild(scriptEl);
// }

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule, { ngZoneEventCoalescing: true })
    .catch((err) => console.error(err));
});
