import { CommonModule, DOCUMENT, isPlatformServer } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, Inject, Input, PLATFORM_ID } from '@angular/core';
import { WINDOW } from '../../tokens';

@Component({
  selector: 'app-scroll-to-top',
  styleUrls: ['./scroll-to-top.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
  template: `
    <div class="scroll-to-top" [ngClass]="{ 'show-scrollTop': windowScrolled }">
      <button [ngStyle]="{ 'background-color': backgroundColor }" (click)="scrollToTop()">
        <span>&#8593;</span>
      </button>
    </div>
  `,
})
export class ScrollToTopComponent {
  public windowScrolled = false;
  @Input()
  public backgroundColor = '#ee7d80';

  constructor(
    @Inject(DOCUMENT)
    private readonly _document: Document,
    @Inject(WINDOW)
    private readonly _window: WindowProxy,
    @Inject(PLATFORM_ID)
    private readonly _platformId: object,
  ) {}

  @HostListener('window:scroll', [])
  public onWindowScroll(): void {
    if (isPlatformServer(this._platformId)) {
      return;
    }
    if (this._window.pageYOffset || this._document.documentElement.scrollTop || this._document.body.scrollTop > 100) {
      this.windowScrolled = true;
    } else if (
      (this.windowScrolled && this._window.pageYOffset) ||
      this._document.documentElement.scrollTop ||
      this._document.body.scrollTop < 10
    ) {
      this.windowScrolled = false;
    }
  }

  public scrollToTop(): void {
    this._window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
}
